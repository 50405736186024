<template>
  <div class="relative">
    <div :class="applyEfect ? 'notification-icon' : ''">
      <div
        class="flex justify-center items-center w-full rounded-xl gap-3 px-4 text-white py-1"
        :class="getCount == 0 ? 'bg-gray-800 opacity-20' : 'bg-orange'"
        @click="showNotifications = !showNotifications"
      >
        <a-icon
          type="bell"
          :style="{ fontSize: '1.6em' }"
          theme="filled"
        ></a-icon>
        <span class="text-lg font-bold">{{ getCount }}</span>
      </div>
    </div>
    <div
      v-if="showNotifications"
      @mouseleave="showNotifications = false"
      class="absolute bg-white rounded-lg right-1/4 notification-list shadow-2xl p-6 mt-4 sm:right-0"
    >
      <div class="mb-5 text-left">
        <h1 class="text-2xl text-gray-800 col-span-2">Notificaciones</h1>
      </div>

      <div v-if="data.length">
        <a-list
          size="small"
          class="relative"
          :loading="loading"
          item-layout="horizontal"
          :data-source="data"
        >
          <a-list-item
            @click="rated(item.complaintId)"
            slot="renderItem"
            slot-scope="item"
            class="text-left px-2 rounded-xl relative flex hover:bg-gray-50"
          >
            <a-list-item-meta
              :description="item.description.replace(/\[|\]/g, '')"
            >
              <a slot="title">{{ item.title || capitalize }} </a>
              <a-icon
                class="mt-10"
                slot="avatar"
                type="bell"
                :style="{ fontSize: '24px', color: '#ffa02a' }"
              />
            </a-list-item-meta>
          </a-list-item>
        </a-list>
      </div>
      <div v-else-if="loading" class="w-full mx-auto mt-7 left-0">
        <a-spin>
          <a-icon
            slot="indicator"
            type="loading"
            style="font-size: 64px"
            spin
          />
        </a-spin>
      </div>
      <div v-else class="relative top-16">
        <span class="text-2xl text-gray-400">No hay notificaciones</span>
      </div>
    </div>
  </div>
</template>
<script>
import AlertListBase from "../Dashboard/Alerts/AlertListBase.vue";

export default {
  components: { AlertListBase },
  data() {
    return {
      loading: true,
      data: [],
      count: "",
      showNotifications: false,
      applyEfect: true,
    };
  },
  computed: {
    getCount: function () {
      if (this.count > 99) return "99+";
      else if (this.count < 1) {
        return 0;
      }
      return this.count;
    },
  },
  created() {
    if(!this.$route.fullPath.includes('dashboard'))
      this.$store.dispatch("session/getNotificationsCustomer");
    this.getData();
  },
  methods: {
    async getData(pageSize = this.pageSize) {
      this.loading = true;
      let data = await this.$store.state.session?.notifications;
      let notifications = data?.lastNotifications.map((item) => {
        return {
          complaintId: item,
          title: "Calificanos ",
          description: `Tu opinión es muy importante para nosotros. Cuéntanos tu experiencia con el proceso para la queja con número de radicado ${item}`,
          viewed: false,
        };
      });
      if (data?.count > 0) {
        this.count = data.count;
        this.data = notifications;
        this.loading = false;
      } else {
        this.data = [];
        this.count = 0;
        this.loading = false;
        this.applyEfect = false;
      }
    },
    rated(complaintId) {
      this.$router.push(`/customer/complaint/calification/${complaintId}`);
    },
    showToast(result = "info", title = "Resultado", msg, btn) {
      const key = `open${Date.now()}`;
      this.$notification[result]({
        message: title,
        description: msg,
        btn: btn
          ? (h) => {
              return h(
                "a-button",
                {
                  props: {
                    type: "primary",
                    size: "small",
                  },
                  on: {
                    click: () => this.$notification.close(key),
                  },
                },
                "Entendido"
              );
            }
          : null,
        key,
        onClose: close,
        style: {
          width: "500px",
          marginLeft: `${350 - 500}px`,
        },
      });
    },
  },
  watch: {
    "$store.state.session.notifications.count": {
      handler(newVal, oldVal) {
        this.getData();
        if (this.count > 0) this.applyEfect = true;
        setTimeout(() => {
          this.applyEfect = false;
        }, 1550);
      },
      deep: true,
    },
    $route() {
        this.showNotifications = false;
      },
  },
};
</script>
<style scoped>
.notification-list {
  width: 30em;
  max-height: 35em;
  min-height: 20em;
  z-index: 9999;
  height: 32em;
  overflow-y: scroll;
}
.notification-icon {
  animation: bounce-icon 1s 2;
  position: relative;
  z-index: 99;
}

@media only screen and (max-width: 500px) {
  .notification-list {
    width: 100vw;
    max-height: 100vh;
    z-index: 9999;
    height: 100vh;
    right: -75%;
    overflow-y: scroll;
    top: 3em;
    margin: 0 auto;
  }
}
@keyframes bounce-icon {
  0%,
  100% {
    transform: translateY(-25%);
    animation-timing-function: cubic-bezier(0.8, 0, 1, 1);
  }
  50% {
    transform: translateY(0);
    animation-timing-function: cubic-bezier(0, 0, 0.2, 1);
  }
}
</style>
