<template>
  <div
    v-if="showUser"
    class="flex items-center gap-7 justify-end justify-self-end"
  >
    <NotificationsEvList/>
    <Notifications />    
    <CustomerNotifications v-if="!havePermissions"/>
    <a-dropdown placement="bottomRight" class="cursor-pointer">
      <a-menu slot="overlay" class="mt-2">
        <a-menu-item key="1"
          ><router-link to="/profile" class="inline" append
            ><a-icon type="user" class="mr-2" />Perfil</router-link
          ></a-menu-item
        >
        <a-menu-divider />
        <a-menu-item key="2" @click="logout">
          <a-icon type="logout" />Cerrar sesión
        </a-menu-item>
      </a-menu>
      <div class="flex justify-center items-center">
        <UserLogo
          :colorIcon="havePermissions ? '#c4c4c4' : ''"
          size="42"
          :bg="havePermissions ? '#c4c4c4' : 'none'"
        />
        <div
          class="mx-3 text-md leading-4 capitalize text-left hidden sm:block"
          :class="havePermissions ? 'text-gray-600' : 'text-white'"
        >
          <b class="m-0 p-0"
            >{{ user.first_name }}
            {{ user.person.person_type == 2 ? "" : user.last_name }}</b
          >
          <br />
          <span
            v-if="havePermissions"
            class="text-gray-500 text-left text-light"
            >administrador</span
          >
        </div>
        <img
          class="hidden sm:block"
          src="@/assets/icons/arrow-down.svg"
          alt=""
        />
      </div>
    </a-dropdown>
  </div>
</template>
<script>
import Notifications from "../../Dashboard/Alerts/Notifications.vue";
import NotificationsEvList from "../../Dashboard/Alerts/NotificationsEvList.vue";
import CustomerNotifications from "@/components/Customer/CustomerNotifications.vue";
import BaseDropdown from "@/components/UI/BaseDropdown";
import UserLogo from "../../Brand/UserLogo.vue";
export default {
  components: {
    Notifications,
    NotificationsEvList,
    CustomerNotifications,
    BaseDropdown,
    UserLogo,
  },
  methods: {
    logout() {
      this.$router.push("/logout");
    },
  },
  computed: {
    user: function () {
      return this.$store.state.session.user;
    },
    havePermissions: function () {
      return this.$store.state.session.modules?.length > 0;
    },
    showUser: function () {
      let show = true;
      if (
        this.$route.path.includes("login") ||
        this.$route.path.includes("signup") ||
        this.$route.path.includes("verify")
      ) {
        show = false;
      }
      return this.user && show;
    },
  },
};
</script>