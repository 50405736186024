<template>
  <nav class="h-16 w-full flex justify-between px-4 sm:px-8 gap-6 mb-2">
    <div class="h-16 flex justify-between w-full">
      <a
        href="https://www.superfinanciera.gov.co/"
        target="_blank"
        rel="noopener"
        class ="h-full block mt-2" 
      >
      <img
          src="@/assets/img/SFC_nav.png"
          style="height: 45px !important;"
          alt="Potencia Vida"
        />
      </a>
     <!-- <img
          src="https://www.superfinanciera.gov.co/info/superfinancierav8/media/bloque531.png"
          class="cursor-pointer sm:hidden md:block xs:hidden"
          alt="Min Hacienda"
      /> -->
    </div>
    <NavbarUserItem v-if="havePermissions"/>
  </nav>
</template>

<script>
import LogoSFC from "@/components/Brand/LogoSFC";
import NavbarUserItem from "./NavbarUserItem.vue";
export default {
  components: {
    LogoSFC,
    NavbarUserItem,
  },
  computed: {
    havePermissions: function () {
      return this.$store.state.session.modules?.length > 0;
    },
  },
};
</script>