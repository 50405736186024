<template>
  <div class="relative" v-if="havePermissions">
    <div :class="applyEfect ? 'notification-icon' : ''">
      <div
        class="flex justify-center items-center w-full rounded-xl gap-3 px-4 text-white py-1"
        :class="getCount == 0 ? 'bg-gray-800 opacity-20' : 'bg-orange'"
        @click="showNotifications = !showNotifications"
      >
        <a-icon
          type="bell"
          :style="{ fontSize: '1.6em' }"
          theme="filled"
        ></a-icon>
        <span class="text-lg font-bold">{{ getCount }}</span>
      </div>
    </div>
    <div
      @mouseleave="showNotifications = false"
      v-if="showNotifications"
      class="absolute bg-white rounded-lg right-1/4 notification-list shadow-2xl pl-4 mt-4 pt-4"
    >
      <AlertListBaseEv
        :notificationBell="true"
        :data="data"
        :loading="loading"
        :filter_date="false"
        :alert_title="true"
        sizeList="small"
        title="Notificaciones"
        @loadMore="onLoadMore"
        @viewed="viewed($event)"
      />
    </div>
  </div>
</template>
<script>
import capitalize from "@/utils/checkForms.js";
import session from "../../../store/modules/session.store";
import AlertListBaseEv from "./AlertListBaseEv.vue";
export default {
  components: { AlertListBaseEv },
  data() {
    return {
      loading: true,
      data: [],
      count: "",
      showNotifications: false,
      applyEfect: true,
    };
  },
  computed: {
    havePermissions: function () {
      let permissions = this.$store.state.session.modules?.filter(
        (module) => module.id == 47
      );
      return permissions?.length ? true : false;
    },
    getCount: function () {
      if (this.count > 99) return "99+";
      else if (this.count < 1) {
        return 0;
      }
      return this.count;
    },
  },
  created() {
    if (this.havePermissions) this.getData();
  },
  methods: {
    getData(pageSize = this.pageSize) {
      this.loading = true;
      let notifications = this.$store.state.session?.notifications;
      if (notifications) {
        this.count = notifications.count;
        this.data = notifications.lastNotifications.map((item) => {
          return {
            ...item,
            title: item.configuration_alert_name,
            description: item.detail_alert,
            viewed: item.viewed,
          };
        });
        this.loadMore = this.count !== this.data.length;
        this.loading = false;
      } else {
        this.data = [];
        this.count = 0;
        this.loading = false;
        this.applyEfect = false;
      }
    },
    onLoadMore() {
      this.$router.push("/dashboard/alerts/notifications_ev");
      this.showNotifications = false;
    },
    async viewed(item) {
      this.data.forEach((alert) => {
        if (alert.id == item) alert.viewed = true;
      });
      let { data, error } = await this.$api.setReadAlertEv({ alert: item });

      if (error) {
        this.data.forEach((alert) => {
          if (alert.id == item) alert.viewed = false;
        });
      } else if (data) {
        this.$store.dispatch("session/getNotificationsEv");
      }
    },
    showToast(result = "info", title = "Resultado", msg, btn) {
      const key = `open${Date.now()}`;
      this.$notification[result]({
        message: title,
        description: msg,
        btn: btn
          ? (h) => {
              return h(
                "a-button",
                {
                  props: {
                    type: "primary",
                    size: "small",
                  },
                  on: {
                    click: () => this.$notification.close(key),
                  },
                },
                "Entendido"
              );
            }
          : null,
        key,
        onClose: close,
        style: {
          width: "500px",
          marginLeft: `${350 - 500}px`,
        },
      });
    },
  },
  watch: {
    "$store.state.session.notifications.count": {
      handler(newVal, oldVal) {
        this.getData();
        if (this.count > 0) this.applyEfect = true;
        setTimeout(() => {
          this.applyEfect = false;
        }, 1550);
      },
      deep: true,
    },
    $route() {
      this.showNotifications = false;
    },
  },
};
</script>
<style soped>
.notification-list {
  width: 40em;
  max-height: 45em;
  min-height: 20em;
  z-index: 9999;
  height: auto;
  overflow-y: scroll;
}
.notification-icon {
  animation: bounce-icon 1s 2;
}

@keyframes bounce-icon {
  0%,
  100% {
    transform: translateY(-25%);
    animation-timing-function: cubic-bezier(0.8, 0, 1, 1);
  }
  50% {
    transform: translateY(0);
    animation-timing-function: cubic-bezier(0, 0, 0.2, 1);
  }
}
</style>