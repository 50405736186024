<template>
  <div
    class="h-12 sm:h-12 w-full flex flex justify-between flex-row sm:flex sm:flex-row sm:items-center justify-starts bg-blue items-start px-4 sm:px-6"
  >
    <a href="https://www.gov.co/home/" target="_blank" rel="noopener">
      <LogoGOV class="h-full w-auto" />
    </a>
    <NavbarUserItem v-if="!havePermissions" class="nav" />
  </div>
</template>

<script>
import LogoGOV from "@/components/Brand/LogoGOV";
import NavbarUserItem from "./Navbar/NavbarUserItem.vue";

export default {
  components: {
    LogoGOV,
    NavbarUserItem,
  },
  computed: {
    havePermissions: function () {
      return this.$store.state.session.modules?.length > 0;
    },
  },
};
</script>

<style>
.nav {
  animation-name: nav-show;
  animation-delay: 0.5s;
  opacity: 0;
  animation-fill-mode: forwards;
}
@keyframes nav-show {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
</style>
